import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Col, Row } from 'react-bootstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import IndexSEOImage from '../images/green-lattice.jpg'
import woodRoofImage from '../images/wood-roof-construction.jpg'

const Practices = () => (
    <StaticQuery
    query={graphql`
        query PracticesMeta {
            site {
                siteMetadata {
                    title
                    description
                    google {
                        googleMapAPIKey
                        googleReCaptchaKey
                    }
                }
            }
        }
    `}
    render={data => (
        <>
        <Layout page='services'>
            <SEO title='Our Services' description={`Ochoa Enterprises offers planning, coordination, budgeting and overseeing every aspect of your residential or commercial build, construction management of a project, and specialty work upon request.`} image={IndexSEOImage} keywords={[``]} />
            <Banner title='Our Services' image={woodRoofImage} subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' />
            <section>
                <Container>
                    <Row>
                        <Col xs='12' sm='12' md='12' lg='12'>
                            <div className='spacer20'></div>
                            <h4>General Contractor</h4>

                            <p>Ochoa Enterprises offers planning, coordination, budgeting and overseeing every aspect of your residential or commercial build. These services can include:</p>

                            <ul>
                                <li><FontAwesomeIcon className='fa-li' icon='chevron-right' /> Setting deadlines and budgets</li>
                                <li><FontAwesomeIcon className='fa-li' icon='chevron-right' /> Coordinating with design professionals</li>
                                <li><FontAwesomeIcon className='fa-li' icon='chevron-right' /> Permit phase</li>
                                <li><FontAwesomeIcon className='fa-li' icon='chevron-right' /> Coordinating with subcontractors, including electricians, plumbers and interior designers</li>
                            </ul>

                            <div className='spacer20'></div>

                            <h4>Construction Management</h4>
                            
                            <p>Ochoa Enterprises provides a hands-on approach to managing construction projects from design concept to completion. Our professionals provide construction management services that are tailored to the size and complexity of your project. Our construction management services can include:</p>

                            <ul>
                                <li><FontAwesomeIcon className='fa-li' icon='chevron-right' /> Scheduling and budgeting evaluations</li>
                                <li><FontAwesomeIcon className='fa-li' icon='chevron-right' /> Accuracy check on cost estimates</li>
                                <li><FontAwesomeIcon className='fa-li' icon='chevron-right' /> Building Information Modeling (BIM)</li>
                                <li><FontAwesomeIcon className='fa-li' icon='chevron-right' /> Monitoring and coordination daily construction activities</li>
                                <li><FontAwesomeIcon className='fa-li' icon='chevron-right' /> Project management</li>
                                <li><FontAwesomeIcon className='fa-li' icon='chevron-right' /> Construction management</li>
                                <li><FontAwesomeIcon className='fa-li' icon='chevron-right' /> Quality Control</li>
                                <li><FontAwesomeIcon className='fa-li' icon='chevron-right' /> Closeout services</li>
                            </ul>

                            <div className='spacer50'></div>

                            <h4>Specialty Work</h4>
                            
                            <p>Copper and slate have been used for many years as materials in the construction industry. Some of the world’s most revered/famous structures were built with copper and slate materials; many historic homes built in the 1600s and 1700s have been preserved by such building materials. The Ochoa team has the unique expertise of many years of working with such high-end materials. Our services are tailor-made to the needs of the specific project. We can provide custom copper work, such as cupolas, copper restoration, built-in gutters, and standing seam copper.</p>

                            <div className='spacer50'></div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
        </>
        )}
    />
)

export default Practices
